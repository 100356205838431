<template>
  <div>
    <section class="main">
      <div class="hero parallax"></div>

      <h2>Mission, Vision, Values</h2>
      <article class="statements">
        <aside class="left-column">
          <div class="mission">
            <h3>Our Mission:</h3>
            <p class="intro">
              Our mission is to provide superior customized security services for our valued clients and business partners by demonstrating exceptional professionalism and using the latest technology innovations in security industry.
            </p>
          </div>
          <div class="vision">
            <h3>Our Vision:</h3>
            <p class="intro">
              We at Gamma Team Security aim to be a top security services provider in the states of IL and WI expanding our presence throughout the United States and beyond.
            </p>
          </div>
        </aside>
        <aside class="values">
          <div>
            <h3>Our Values:</h3>
            <p class="intro">
              <span class="item" v-for="(value, i) in values" :key="i">
                <strong>{{ value.key }}</strong> {{ value.label }}
              </span>
            </p>
          </div>
        </aside>
      </article>

      <call-to-action :link_back=false></call-to-action>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Mission',
  data: () => ({
    values: [
      { key: 'Leadership', label: 'we rise by lifting others' },
      { key: 'Quality', label: 'we deliver results of intelligent efforts' },
      { key: 'Teamwork', label: 'together we achieve remarkable goals' },
      { key: 'Relationships', label: 'we are in business for our clients' },
      { key: 'Respect', label: 'we do it right, even if no one is watching' },
      { key: 'Integrity', label: 'we rise by lifting others' },
      { key: 'Enthusiasm', label: 'we are challenged, and we overcome' },
      { key: 'Efficiency', label: 'we do better what is already being done' }
    ]
  }),
  components: {
  },
  methods: {
  }
}
</script>

<style scoped>
  section.main article {
    position: relative;
    padding: 0;
  }
  section.main article:after {
    content: '';
    display: block;
    clear: both;
  }
  section.main article aside {
  }
  section.main article aside.left-column {
    width: 60%;
    background-color: transparent;
    padding: 0;
  }
  section.main article aside.mission:after, section.main article aside.vision:after {
    content: '';
    display: block;
    clear: both;
  }
  section.main article aside.values {
    width: 38%;
    padding: 0;
    float: right;
    padding: 15px 20px 0 20px;
  }
  section.main article p.intro strong {
    font-weight: 600;
    color: #2a7afa;
    letter-spacing: 1px;
  }
  section.main article p.intro > span {
    display: block;
    line-height: 1.8em;
    font-size: .9em;
  }
  section.main article p.intro strong:after {
    content: ':';
    display: inline;
  }

  @media screen and (max-width: 1000px) {
    section.main article p.intro {
      text-align: left;
    }
    section.main article aside.left-column {
      width: 50%;
    }
    section.main article aside.values {
      width: 48%;
    }
  }

  @media screen and (max-width: 770px) {
    section.main h2 {
      font-size: 2.5em;
    }
    section.main article p.intro {
      text-align: center;
    }
    section.main article aside.values strong {
      display: block;
      text-transform: uppercase;
    }
    section.main article aside.values strong:after {
      content: '';
    }
    section.main article aside.values span {
      text-transform: capitalize;
    }
  }

  @media screen and (max-width: 650px) {
    #content {
      width: 100%;
    }
    section.main h2 {
      padding: 0 15px;
    }
    section.main aside {
      margin: 0;
    }
    section.main article aside.left-column {
      width: 100%;
      padding: 0 15px;
    }
    section.main article aside.left-column div {
      margin: 0 0 20px 0;
      padding: 10px 15px;
      background-color: rgba(0,0,0,.5);
      border-radius: 15px;
      text-align: center;
    }
    section.main article aside.values {
      display: block;
      float: none;
      width: 100%;
      text-align: center;
      padding: 0 15px;
      background-color: transparent;
    }
    section.main article aside.values div {
      padding: 15px 0;
      border-radius: 15px;
      background-color: rgba(0,0,0,.5);
    }
    section.main article aside.values p.intro {
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    section.main article aside.values p.intro span.item  {
      width: 49%;
    }
  }

  @media screen and (max-width: 500px) {
    section.main h2 {
      font-size: 2em;
    }
    section.main article aside.values p.intro  {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 414px) {
    section.main article aside.values p.intro  {
      display: block;
      flex-wrap: nowrap;
      justify-content: normal;
    }
    section.main article aside.values p.intro span.item  {
      width: 100%;
      display: block;
      float: none;
      margin: 0 0 15px 0;
    }
  }
</style>
